export default {
    global: {
        outer: 'w-full mb-3',
        inner: 'relative',
        label: 'block mb-3 leading-none text-black font-bold text-base',
        input: 'block w-full px-4 py-2 bg-white border border-gray-border rounded-full text-blue text-base font-bold focus:outline-none placeholder-gray-light focus:ring-1 focus:ring-pink focus:border-pink  min-h-10 -my-[1px] disabled:bg-gray-medium',
        help: 'text-xs text-blue mt-2',
        messages: 'list-none p-0 mt-3 mb-3 font-semi text-xs text-pink  ',
        message: 'mb-1 flex items-center gap-2 leading-tight',
        prefixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 left-3 pointer-events-none',
        suffixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none',
    },
    search: {
        input: 'pl-10 appearance-none',
    },
    button: {
        input: '$reset relative w-full px-4 py-2 text-white transition-colors duration-300 border border-transparent rounded bg-green-dark hover:bg-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:pink min-h-10 flex-center focus:text-white',
        wrapper: 'mt-4',
    },
    checkbox: {
        input: '$reset block w-4 h-4 mr-4 rounded accent-blue-light focus:outline-none disabled:bg-gray-100',
        wrapper: 'flex items-center py-2',
        label: '$reset block leading-tight mb-0 select-none font-bold',
        decorator: 'hidden',
        legend: 'block mb-3 leading-none text-blue text-base',
    },
    select: {
        outer: 'min-w-20',
        input: 'appearance-none',
        selectIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none',
    },
    textarea: {
        input: 'h-43',
    },
    radio: {
        legend: 'block mb-2 leading-tight font-medium',
        wrapper: 'flex items-center gap-3',
        label: '$reset',
        input: '$reset focus:ring-green h-4 w-4 text-green border-blue appearance-none rounded-full bg-white border-gray-darker border checked:bg-pink p-[2px] bg-clip-content',
        inner: 'h-4',
        options: 'flex flex-col gap-2',
    },
    file: {
        wrapper: 'relative mb-6 md:mb-12',
        input: '$reset absolute top-0 left-0 bottom-0 right-0 opacity-0 py-6 z-40 cursor-pointer',
        inner: 'w-full',
        prefixIcon: '$reset absolute top-1/2 -translate-y-1/2 w-6 left-0 pointer-events-none',
        outer: 'flex items-center relative',
        noFiles: 'pl-45 top-3 relative',
        fileName: 'top-12 relative',
        fileRemove: 'top-3 -right-20 absolute text-pink flex gap-2 items-center hover:text-primary cursor-pointer z-50',
        fileRemoveIcon: ' w-[16px]',
        help: 'pointer-events-none bg-black cursor-pointer text-white rounded-2xl py-3 absolute top-6 md:py-2 text-center text-xs md:text-base px-4 h-10 w-40',
        fileList: 'py-5',
    },
};
