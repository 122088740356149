import DataObjectParser from 'dataobject-parser';
import { pickBy } from 'lodash';

export function objectMap(obj, fn) {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

export function mapErrors(errors, remap = {}) {
    errors = objectMap(errors, (error) => [error]);
    errors = DataObjectParser.transpose(errors).data();

    for (const [key, value] of Object.entries(remap)) {
        if (Object.hasOwnProperty.call(remap, key)) {
            errors[key] = errors[value];
        }
    }
    console.table(errors);
    return errors;
}

export function filterEmpty(data) {
    return pickBy(data, (v, k, i) => v !== '' && v !== null);
}

export function getTimesBetween(start, end, step = 'day', stepSize = 1) {
    let localStart = start;
    let dates = [];
    while (localStart.isBefore(end)) {
        dates.push(localStart);
        localStart = localStart.add(stepSize, step);
    }
    return dates;
}

export function pick(obj, fields) {
    return fields.reduce((acc, cur) => ((acc[cur] = obj[cur]), acc), {});
}

export function capitalFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function arrayIncludesAny(array, values) {
    if(!Array.isArray(array)) {
        array = [array];
    }
   
    array = array.map((value) => value.toLowerCase());
    values = values.map((value) => value.toLowerCase());

    return values.some((value) => array.includes(value));
}
